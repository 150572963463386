import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"
import classes from './style.css';


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 5" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 5</h1>
      <p>God lørdag, mamma!</p>
      <p>Med dine nye Airpods kan du kose deg med noe godt på øret når du f.eks. triller rundt på meg.</p>
      <p>Om du synes det er vanskelig å finne en god sang eller podkast, så kan du alltids høre på sangen jeg komponerte til deg.</p>
      <p>♫ <a className="litjen-link" href="https://firebasestorage.googleapis.com/v0/b/choirmate-dev/o/16.29_04.12.20_1.59%2C_great-mozart.mp3?alt=media&token=97a41a02-2967-4bd1-83a7-0db26257f37e" target="_blank"> Den finner du her</a>.</p>
      <p>Stor suss fra Litjen<br/>(Ja, jeg kan nesten susse nå)</p>
    </div>
  </Layout>
)

export default LitjenLuke